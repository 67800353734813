.header {
  width: 100%;
  /* min-width: 700px; */
  /* background-color: #75c236; */
  
  background: linear-gradient(135deg, rgb(5, 145, 91) 0%, rgb(42, 141, 50) 35%, rgb(187, 255, 0) 100%);
  
  margin-top: 0.8em;
  box-shadow: 2px 2px 8px rgba(95, 95, 95, 0.1);
  z-index:3;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.header li a {
  display: block;
  font-size: 1em;
  padding: 0.9rem 2rem;
  color:#ffffff;
}

.header li a:hover, .header .menu-btn:hover {
  font-weight: bold;
}

.header .logo {
  /* display: block;
  float: left;
  font-size: 2em;
  padding: 10px 20px;
  text-decoration: none; */
  display: block;
  float: left;
  font-size: 1em;
  padding: 18px 20px;
  font-family: 'Playfair Display';
  /* text-transform: uppercase; */
  color:#ffffff;
  text-decoration: none;
}

.header .navigation {
    font-family: 'Playfair Display';
    font-weight: 500;
    list-style: none;
    padding: 0;
    /* display: flex;
    align-items: baseline;
    justify-content: center;
    animation: navigation-appear 0.5s ease-out forwards; */

    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
}

/* .header .navigation {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
} */

.navigation li a > .active {
    font-weight: bold;
}

.navigation li a, .navigation ul li a:visited {
    /* color: #333333; */
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {  
    /* .nav-bar-container {
      flex-direction: column;
      padding: 0;
    } */
  
    /* .navigation {
      flex-direction: column;
      align-items: center;
    }

    .navigation li a {
      font-size: 1em;
  } */
  }
/* 
  @keyframes navigation-appear {
    from {
      opacity: 0;
      transform: translateY(3rem);
    }
  
    to {
      opacity: 1;
      transform: translateY(0);
    }
  } */





 /* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .navigation {
  max-height: fit-content;
  text-align: left;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 800px) {
  .header .logo {
    font-size: 1.8em;
    padding:8px 20px;
  }

  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    position: fixed;
  }

  .header li {
    float: left;
  }
  .header li a {
    font-size: 1.4em;
    padding: 0.9rem 1.9rem;
  }
  .header .navigation {
    max-height: none;
    margin: 0 15px;
  }
  .header .menu-icon {
    display: none;
  }
}