.header-container {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: center; */
    position: relative;
    width:100%;
    height:75vh;
    background: url("header.jpg") no-repeat center bottom;
    background-size: cover;
    background-attachment: fixed;
    text-align: center;
    padding-top:0.5rem;
}

.header-container h1.tagline {
    /* align-self: flex-end; */
    position: absolute;
    bottom: 2rem;
    left:0;
    right:0;
    font-weight: 500;
    color:white;
    width:40%;
    min-width: 350px;
    margin:0 auto;
    padding: 0 1rem 3rem;
    text-shadow: 2px 2px 10px #333030;
    border:0;
}

.header-container h1.tagline span {
    font-style: italic;
}

/* .top-bar div {
    display: inline-block;
} */

/* .logo {
    position: relative;
    top:-15px;
    background-color: white;
    line-height: 60px;
    font-family: 'Playfair Display';
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5em;
    width: 380px;
    margin:0 auto;
    box-shadow: 2px 2px 8px rgba(95, 95, 95, 0.1);
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.logo span {
    color: #62b952;
    font-weight: 100;
} */

/* .nav-bar-container { */
    /* display: flex;
    justify-content: space-around;
    align-items: center; */
    /* background-color: white; */
    /* margin: 0 10%;
    position: relative; */
    /* top: -20px; */
/* } */

@media only screen and (max-width: 600px) {  
    .header-container h1.tagline {
      font-size: 1.2em;
    }

    .logo {
        font-size: 1em;
        width: 250px;
    }
  }