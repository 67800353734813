@import url('https://fonts.googleapis.com/css2?family=Lora');
@import url('https://fonts.googleapis.com/css2?family=Lato');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #333333;
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', 'Lora', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2 {
  display: inline-block;
  font-family: 'Playfair Display';
  /* border-bottom: 2px solid #3c9b2b; */
}

h1 {
  font-size: 2em;
  text-transform: uppercase;
}

h2 {
  font-size: 1.3em;
}

p {
  font-size: 1.1em;
  text-align: center;
}

table {
  width: 100%;
}

th {
  font-size: 1.1em;
}

th, td {
  padding: 0.5em;
  border-bottom: 1px dashed #cec9c9;
}

