.main-container {
    width: 90%;
    max-width: 60rem;
    /* display: flex;
    justify-content: center; */
    margin:0 auto;
    padding: 1em 0.5em;
    font-size: 1.05em;
}

.highlights {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    top: -50px;
    margin-bottom: -50px;
    font-size:0.92em;
    animation: highlights-appear 0.5s ease-out forwards;
}

.highlight {
    background-color: #ffffff;
    width: 300px;
    height: 300px;
    min-width: 200px;
    margin: 0 1rem 1rem 1rem;
    padding: 1rem;
    border: 1px solid #ffffff;
    background-clip:content-box;
    box-shadow: 0 1px 40px rgba(161, 161, 161, 0.2);
}

.highlight .h-icon {
    margin-top:1.5em;
}

.highlight p {
    color:#857e7e;
    margin: 1rem;
    text-align: center;
}

ul.facilities {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    text-align: left;
    padding: 0;
}

ul.facilities li {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    padding: 0.8em;
    margin: 0.5em;
    color: #6f6b6a;
    border-bottom: 1px dashed #b6d8a8;
    /* background-color: #eef5eb; */
}

ul.facilities li div {
    min-height: 70px;
    text-align: center;
}

.facilities .f-icon {
    width: 64px;
    margin-bottom: 5px;
    filter: invert(64%) sepia(38%) saturate(798%) hue-rotate(42deg) brightness(93%) contrast(90%);
}

@keyframes highlights-appear {
    from {
      opacity: 0;
      transform: translateY(3rem);
    }
  
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media only screen and (max-width: 600px) {
    ul.facilities li {
        width: 130px;
        height: 140px;
        font-size: 0.8em;
        padding: 0.6em;
    }

    .facilities .f-icon {
        width: 50px;
    }
  }