ul.location-from {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    text-align: left;
    padding: 0;
}

ul.location-from li {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 23%;
    min-width:200px;
    height: 200px;
    padding: 0.8em;
    margin:0.5em;
    color: #6f6b6a;
    /* border-bottom: 1px dashed #b6d8a8; */
    /* background-color: #eef5eb; */
    /* background: linear-gradient(135deg, #c2e4b4 0%, #eef5eb 35%); */
    border: 2px dashed #d9ecd2;
}

ul.location-from li div {
    min-height: 40px;
    text-align: center;
}

ul.location-from h1 {
    width: 100%;
    color:#7cb626;
    font-size: 3em;
    text-align: center;
    margin: -5px 0 0;
}

ul.location-from .dist-measure {
    font-size: 0.6em;
    display: inline-block;
    margin-left: -5px;
}

.contact-phone {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-phone span {
    margin-left: 6px;
}

.contact-phone img {
    width:46px;
    margin: 0 2px;
}

@media only screen and (max-width: 600px) {
    ul.location-from li {
        width: 140px;
        min-width: 140px;
        height: 140px;
        font-size: 0.8em;
        padding: 0.6em;
    }
  }